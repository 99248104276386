<template>
  <button :class="buttonStyles" @click="emitClick"><slot></slot></button>
</template>

<script>
export default {
  name: "Button",
  props: {
    buttonType: {
      type: String,
      default: "generic",
    },
  },
  computed: {
    buttonStyles: function () {
      let styles =
        "px-3 py-2 border-2 rounded-md outline-none focus:outline-none";
      let typeStyles;

      switch (this.buttonType) {
        case "warning":
          typeStyles =
            "text-gray-100 bg-red-400 border-red-400 hover:text-red-500 hover:bg-white";
          break;

        case "plain":
          typeStyles =
            "text-endeos bg-transparent border-endeos hover:text-gray-700 hover:border-gray-700";
          break;

        default:
          typeStyles =
            "text-white bg-endeos border-endeos hover:text-endeos hover:bg-white";

          break;
      }

      styles += " " + typeStyles;
      return styles;
    },
  },

  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style>
</style>