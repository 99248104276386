<template>
  <div>
    <h2 class="h2 border-b-2 border-gray-100">Formulario de pago</h2>

    <form>
      <div
        class="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:justify-between md:items-center"
      >
        <InputText
          class="flex-grow"
          v-model="formInfo.name"
          inputName="name"
          placeholder="Tu nombre"
        />
        <InputText
          class="flex-grow"
          v-model="formInfo.surname"
          inputName="surname"
          placeholder="Tus apellidos"
        />
        <InputText
          class="flex-grow"
          v-model="formInfo.dni"
          inputName="dni"
          placeholder="DNI"
        />
      </div>
      <div class="mt-2">
        <InputText
          v-model="formInfo.email"
          inputName="email"
          placeholder="Correo electrónico"
        />
      </div>
      <div
        class="mt-2 flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:justify-between md:items-center"
      >
        <InputText
          class="flex-grow"
          v-model="formInfo.company"
          inputName="company"
          placeholder="Empresa"
        />
        <InputText
          class="flex-grow"
          v-model="formInfo.nif"
          inputName="nif"
          placeholder="Número de Identificación Fiscal de la empresa"
        />
      </div>
      <div class="mt-2">
        <InputText
          v-model="formInfo.address.street"
          inputName="street"
          placeholder="Calle"
        />
      </div>
      <div
        class="mt-2 flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:justify-between md:items-center"
      >
        <InputText
          v-model="formInfo.address.city"
          inputName="city"
          placeholder="Ciudad"
        />
        <InputText
          class="flex-grow"
          v-model="formInfo.address.zip"
          inputName="zip"
          placeholder="Código Postal"
        />
        <InputText
          class="flex-grow"
          v-model="formInfo.address.country"
          inputName="country"
          placeholder="País"
        />
      </div>
      <div class="mt-2">
        <InputText
          class="flex-grow"
          v-model="formInfo.card"
          inputName="card"
          placeholder="Número tarjeta de crédito"
        />
      </div>
    </form>
    <div class="mt-4">
      <Button class="w-full" @click="proceedCheckout"
        >Realizar pago {{ formatAmount(amount) }}</Button
      >
    </div>
  </div>
</template>

<script>
import InputText from "../atoms/InputText";
import Button from "../atoms/Button";

import { priceFormatterEur } from "../../utils/numberFormats";

export default {
  name: "FormCheckout",

  components: { InputText, Button },

  props: {
    amount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formInfo: {
        name: "",
        surname: "",
        email: "",
        dni: "",
        company: "",
        nif: "",
        address: {
          street: "",
          city: "",
          country: "",
          zip: "",
        },
        card: "",
      },
    };
  },

  methods: {
    formatAmount(amount) {
      return priceFormatterEur(amount);
    },

    proceedCheckout() {
      //check form data todo

      this.$emit("proceed-checkout", this.formInfo);
    },
  },
};
</script>

<style>
</style>