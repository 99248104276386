<template>
  <div>
    <div v-if="data.maintenance">
      <p>
        Se están llevando a cabo tareas de mantenimiento, vuelve a probar en un
        rato
      </p>
    </div>

    <div
      class="flex flex-col space-y-4 p-3 rounded-md shadow-md"
      v-if="
        !data.maintenance &&
        data.data !== undefined &&
        data.data.results.length > 0
      "
    >
      <div>
        <h2 class="h2 border-b-2 border-gray-100">
          Elige los dominios que quieras
        </h2>

        <ul>
          <li v-for="(result, index) in data.data.results" :key="index">
            <DomainCheckItem
              :domain="result"
              @change-selection="updateSelectedDomains"
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import DomainCheckItem from "../molecules/DomainCheckItem";

import { priceFormatterEur } from "../../utils/numberFormats";

export default {
  name: "DomainCheckResults",

  components: { DomainCheckItem },

  props: {
    data: {
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    domainPrice(amount) {
      return priceFormatterEur(amount);
    },

    updateSelectedDomains(item) {
      const cartItem = {
        name: `Dominio ${item.domain.domain}`,
        amount: item.domain.price.reseller.price,
      };

      if (item.selected) {
        this.$emit("cart-add-item", cartItem);
      } else {
        this.$emit("cart-remove-item", cartItem);
      }
    },
  },
};
</script>