<template>
  <div id="app" class="">
    <SearchDomain />
  </div>
</template>

<script>
import SearchDomain from "./components/organisms/SearchDomain";

export default {
  name: "App",
  components: {
    SearchDomain,
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>
