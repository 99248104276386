<template>
  <label :for="inputName" class="block">
    <input
      type="text"
      :name="inputName"
      :placeholder="placeholder"
      class="py-2 px-3 w-full text-base text-gray-500 border border-gray-300 rounded-md shadow-sm outline-none focus:outline-none focus:border-endeos"
      :value="inputValue"
      @input="handleInput"
    />
  </label>
</template>

<script>
export default {
  name: "InputText",
  props: {
    inputValue: {
      type: String,
      default: "",
    },
    inputName: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>