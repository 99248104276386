<template>
  <div
    class="flex justify-between p-2 rounded-md mb-1"
    :class="domainCustomStyles"
    @click="toggleSelected"
  >
    <span>{{ formatDomain(domain.domain, domain.status) }}</span>
    <div class="flex space-x-4">
      <span>
        {{ domainPrice(domain.price.reseller.price) }}
      </span>
      <transition name="slidefromright">
        <span v-if="domainIsFree && selected">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
        </span>
      </transition>
    </div>
  </div>
</template>

<script>
import { priceFormatterEur } from "../../utils/numberFormats";

export default {
  name: "DomainCheckItem",

  components: {},

  props: {
    domain: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selected: false,
    };
  },

  computed: {
    domainIsFree() {
      return this.domain.status === "free";
    },

    domainCustomStyles() {
      if (this.domainIsFree) {
        return "text-green-500 text-lg hover:bg-green-50 hover:shadow-sm cursor-pointer";
      } else {
        return "text-gray-400 text-sm hover:bg-gray-50";
      }
    },
  },

  methods: {
    formatDomain(domain, status) {
      const availability = status === "free" ? "" : "(No disponible)";
      return `${domain} ${availability}`;
    },

    domainPrice(amount) {
      return priceFormatterEur(amount);
    },

    toggleSelected() {
      if (this.domainIsFree) {
        this.selected = !this.selected;
        this.$emit("change-selection", {
          selected: this.selected,
          domain: this.domain,
        });
      }
    },
  },
};
</script>