/**
 * 
 * This function returns the available hosting plans
 */
export async function getHostingPlans() {
  // api call to do

  // mock implementation
  const delay = ms => new Promise(res => setTimeout(res, ms))
  await delay(1000)

  const result = [
    {
      plan: "Basic",
      features: ["Espacio: 2GB", "Transferencia: 5GB", "Buzones correo: 5", "Cuantas FTP: 1", "Bases de datos MySQL: 1", "Dominios: 1"],
      price: "25",
      highlight: false,
    },
    {
      plan: "Standard",
      features: ["Espacio: 4GB", "Transferencia: 10GB", "Buzones correo: 20", "Cuantas FTP: 5", "Bases de datos MySQL: 3", "Dominios: 5"],
      price: "49",
      highlight: true,
    },
    {
      plan: "Professional",
      features: ["Espacio: 6GB", "Transferencia: Ilimitada", "Buzones correo: 50", "Cuantas FTP: 10", "Bases de datos MySQL: Ilimitadas", "Dominios: 10"],
      price: "99",
      highlight: false,
    },
    {
      plan: "Enterprise",
      features: ["Espacio: 12GB", "Transferencia: Ilimitada", "Buzones correo: 150", "Cuantas FTP: 30", "Bases de datos MySQL: Ilimitadas", "Dominios: 15"],
      price: "199",
      highlight: false,
    }
  ]

  return result
}