<template>
  <div :class="planStyle + ' ' + selectedPlanStyle">
    <div class="p-8 text-3xl font-bold text-center">{{ name }}</div>

    <div class="border-0 border-grey-light border-t border-solid text-sm">
      <div
        v-for="(feature, index) in features"
        :key="index"
        class="text-center border-0 border-grey-light border-b border-solid py-4"
      >
        {{ feature }}
      </div>
    </div>
    <div class="text-center px-8 pt-8 text-3xl mt-auto">
      <span
        v-if="oldPrice"
        class="text-grey-light italic text-sm line-through mr-1"
      >
        {{ oldPrice }}
      </span>
      {{ price }}
    </div>
    <div class="text-center mt-8 mb-8">
      <Button buttonType="plain" @click="selectPlan">Seleccionar</Button>
    </div>
  </div>
</template>

<script>
import Button from "../atoms/Button";

export default {
  name: "PlanCard",

  components: { Button },

  props: {
    name: {
      type: String,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    price: {
      type: String,
      required: true,
    },
    oldPrice: {
      type: String,
      required: false,
    },
  },

  computed: {
    planStyle() {
      if (this.highlight) {
        return "flex-1 lg:flex-initial lg:w-1/4 rounded-lg border-2 bg-white mt-4 lg:-mt-4 shadow-lg z-30 flex flex-col";
      }

      return "sm:flex-1 lg:flex-initial lg:w-1/4 rounded-lg border-2 bg-white mt-4 flex flex-col";
    },

    selectedPlanStyle() {
      if (this.selected) {
        return "border-endeos";
      }

      return "border-transparent";
    },
  },

  methods: {
    selectPlan() {
      this.$emit("plan-selected", this.name);
    },
  },
};
</script>

<style>
</style>