<template>
  <div>
    <h2 class="h2 border-b-2 border-gray-100">Carrito</h2>

    <ul class="mb-4">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="flex justify-between text-gray-700 text-lg"
      >
        <span>{{ item.name }}</span>
        <div class="flex items-center space-x-4">
          <span>{{ itemPrice(item.amount) }}</span>

          <span>
            <svg
              @click="emitRemoveItem(item)"
              class="w-4 h-4 hover:text-red-500 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </span>
        </div>
      </li>
    </ul>

    <div class="flex flex-col items-end space-y-4">
      <p class="text-right text-xl">Total: {{ cartTotalFormatted }}</p>
    </div>
  </div>
</template>

<script>
import { priceFormatterEur } from "../../utils/numberFormats";

export default {
  name: "Cart",

  components: {},

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      amount: 0,
    };
  },

  computed: {
    cartTotalFormatted() {
      const reducer = (accumulator, item) =>
        accumulator + parseFloat(item.amount);

      const totalAmount = this.items.reduce(reducer, 0);
      this.updateAmount(totalAmount);
      return priceFormatterEur(totalAmount);
    },
  },

  methods: {
    itemPrice(amount) {
      return priceFormatterEur(amount);
    },

    updateAmount(newAmount) {
      this.amount = newAmount;
      this.$emit("cart-amount-updated", newAmount);
    },

    emitRemoveItem(item) {
      this.$emit("cart-remove-item", item);
    },
  },
};
</script>