/**
 * 
 * This function search the availability of a given domain name
 */
export async function domainCheck(searchTerm) {
  // api call to do... (https://docs.openprovider.com/doc/all#operation/CheckDomain)

  // mock implementation
  if (!searchTerm.trim().length) {
    return false
  }
  const delay = ms => new Promise(res => setTimeout(res, ms))
  await delay(1000)

  const result = {
    "code": 0,
    "data": {
      "results": [
        {
          "claim_key": "",
          "domain": searchTerm + ".com",
          "is_premium": false,
          "premium": {
            "currency": "EUR",
            "price": {
              "create": 0
            }
          },
          "price": {
            "product": {
              "currency": "EUR",
              "price": 8.06
            },
            "reseller": {
              "currency": "EUR",
              "price": 8.06
            }
          },
          "reason": "Domain exists",
          "status": "active",
          "whois": ""
        },

        {
          "claim_key": "",
          "domain": searchTerm + ".es",
          "is_premium": false,
          "premium": {
            "currency": "EUR",
            "price": {
              "create": 0
            }
          },
          "price": {
            "product": {
              "currency": "EUR",
              "price": 8.06
            },
            "reseller": {
              "currency": "EUR",
              "price": 8.06
            }
          },
          "reason": "",
          "status": "free",
          "whois": ""
        },

        {
          "claim_key": "",
          "domain": searchTerm + ".eu",
          "is_premium": false,
          "premium": {
            "currency": "EUR",
            "price": {
              "create": 0
            }
          },
          "price": {
            "product": {
              "currency": "EUR",
              "price": 8.06
            },
            "reseller": {
              "currency": "EUR",
              "price": 8.06
            }
          },
          "reason": "",
          "status": "free",
          "whois": ""
        }
      ]
    },
    "desc": "",
    "maintenance": false
  }
  return result
}