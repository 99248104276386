<template>
  <div>
    <InputText
      inputName="nombre-dominio"
      placeholder="Busca el dominio que quieres registrar..."
      v-model="domainValue"
      @input="searchDomainMatch"
    />

    <transition name="fade">
      <DomainCheckResults
        v-show="showingDomainResults"
        :data="domainResults"
        :searchTerm="domainNameFormatted"
        @show-hosting-plans="showHostingPlans"
        @cart-add-item="addCartItem"
        @cart-remove-item="removeCartItem"
      />
    </transition>

    <transition name="fade">
      <div v-show="showingHostingPlans" class="p-3 rounded-md shadow-md">
        <HostingPlans @cart-add-hostingitem="addHostingCartItem" />

        <!-- <a class="" href="#" @click="showDomainResults">ver dominios</a> -->
      </div>
    </transition>

    <transition name="slidefromtop">
      <div
        class="flex flex-col space-y-4 p-3 rounded-md shadow-md"
        v-show="cartItems.length && !showingProcessComplete"
      >
        <Cart
          :items="cartItems"
          @cart-remove-item="removeCartItem"
          @cart-amount-updated="updateCartAmount"
        />

        <div class="text-right" v-if="!showingCheckoutForm">
          <Button buttonType="generic" @click="nextStep"> Continuar </Button>
        </div>
      </div>
    </transition>

    <transition name="slidefromtop">
      <div
        v-show="showingCheckoutForm && cartItems.length"
        class="p-3 rounded-md shadow-md"
      >
        <FormCheckout
          :amount="cartAmount"
          @proceed-checkout="proceedCheckout"
        />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="showingProcessComplete" class="p-3 rounded-md shadow-md">
        <div class="text-endeos text-center">
          <svg
            class="w-28 inline"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
            ></path>
          </svg>

          <h2 class="text-4xl text-center mt-2">
            ¡Proceso completado con éxito! Revisa tu bandeja de entrada, te
            hemos enviado más detalles
          </h2>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import InputText from "../atoms/InputText";
import DomainCheckResults from "../molecules/DomainCheckResults";
import Cart from "../molecules/Cart";
import Button from "../atoms/Button";
import HostingPlans from "../organisms/HostingPlans";
import FormCheckout from "../molecules/FormCheckout";
import { domainCheck } from "../../services/openprovider/domain";
import _ from "lodash";

export default {
  name: "SearchDomain",

  components: {
    InputText,
    DomainCheckResults,
    Cart,
    Button,
    HostingPlans,
    FormCheckout,
  },

  data() {
    return {
      domainValue: "",
      domainResults: false,
      hostingPlans: false,
      showingDomainResults: false,
      showingHostingPlans: false,
      showingCheckoutForm: false,
      showingProcessComplete: false,
      cartItems: [],
      cartAmount: 0,
    };
  },

  computed: {
    domainNameFormatted() {
      return this.domainValue.toLowerCase().trim().split(" ").join("");
    },
  },

  methods: {
    searchDomainMatch: _.debounce(async function () {
      this.domainResults = false;
      const searchTerm = this.domainNameFormatted;
      // api call to search domain
      this.domainResults = await domainCheck(searchTerm);
      this.showDomainResults();
    }, 800),

    showHostingPlans() {
      this.showingDomainResults = false;
      this.showingHostingPlans = true;
      this.showingCheckoutForm = false;
    },

    showDomainResults() {
      this.showingDomainResults = true;
      this.showingHostingPlans = false;
      this.showingCheckoutForm = false;
    },

    showCheckoutForm() {
      this.showingDomainResults = false;
      this.showingHostingPlans = false;
      this.showingCheckoutForm = true;
    },

    showProcessComplete() {
      this.showingDomainResults = false;
      this.showingHostingPlans = false;
      this.showingCheckoutForm = false;
      this.showingProcessComplete = true;
    },

    nextStep() {
      if (this.showingDomainResults) {
        this.showHostingPlans();
      } else if (this.showingHostingPlans) {
        this.showCheckoutForm();
      }
    },

    addCartItem(item) {
      this.cartItems.push(item);
    },

    addHostingCartItem(item) {
      /**
       *
       * MODIFICAR ESTO BUSCANDO POR KEY 'TYPE' O SIMILAR
       */
      this.cartItems = this.cartItems.filter(
        (cartItem) => !cartItem.name.includes("Hosting")
      );
      this.cartItems.push(item);
    },

    removeCartItem(item) {
      this.cartItems = this.cartItems.filter(
        (cartItem) => cartItem.name !== item.name
      );
    },

    updateCartAmount(newAmount) {
      this.cartAmount = newAmount;
    },

    proceedCheckout(formCheckout) {
      console.log({ formCheckout });

      this.showProcessComplete();
    },
  },
};
</script>