<template>
  <div class="w-full bg-blue pt-8">
    <div class="flex flex-col lg:flex-row justify-center mb-6 sm:mb-0">
      <PlanCard
        v-for="(plan, index) in plans"
        :key="index"
        :name="plan.plan"
        :features="plan.features"
        :price="planPrice(plan.price)"
        :highlight="plan.highlight"
        :selected="'plan' in selectedPlan && plan.plan === selectedPlan.plan"
        @plan-selected="selectPlan"
      />
    </div>
  </div>
</template>

<script>
import { priceFormatterEur } from "../../utils/numberFormats";
import { getHostingPlans } from "../../services/hosting/plans";
import PlanCard from "../molecules/PlanCard";

export default {
  name: "HostingPlans",

  components: { PlanCard },

  data() {
    return {
      plans: [],
      selectedPlan: {},
    };
  },

  mounted() {
    this.fetchHostingPlans();
  },

  methods: {
    planPrice(amount) {
      return priceFormatterEur(amount);
    },

    async fetchHostingPlans() {
      this.plans = await getHostingPlans();
    },

    selectPlan(name) {
      this.selectedPlan = this.plans.find((plan) => plan.plan === name);

      const cartItem = {
        name: `Hosting ${this.selectedPlan.plan}`,
        amount: this.selectedPlan.price,
      };

      this.$emit("cart-add-hostingitem", cartItem);
    },
  },
};
</script>

<style>
</style>